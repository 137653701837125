import { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useForm, UseFormReturn } from "react-hook-form";
import {
  CARD_DELIVERY_TYPES,
  CARD_TYPE_PHYSICAL,
  CARD_TYPE_VIRTUAL,
  ECardType,
} from "../../constants/CardConstants";
import { DeliveryType } from "../../types/CardTypes";
import { useStoreClient } from "../../store/useStoreClient";
import { selectClientCards } from "../../store/selectors/ClientSelectors";
import { ExClient, ICard, ICreateCardResponse } from "../../ExClient";
import { useTUCardStore } from "../../store/useTopUpCard";
import shallow from "zustand/shallow";

type Delivery = {
  firstName: string;
  lastName: string;
  phone: string;
  companyName: string;
  countryCode: string;
  address1: string;
  address2: string;
  city: string;
  PostalCode: string;
  dispatchMethod: string;
};

type FormData = {
  company: string;
  nickname: string;
  type: string;
  name: string;
  password3ds: string;
  password3ds_confirm: string;
  dispatchMethod: string;
  use_company: boolean;
  pin: number;
  pin_confirm: number;
  username: string;
} & Delivery;

type Props = {
  onSuccess: (type: string) => void;
  msgSuccess?: string;
};

type Return = {
  onSubmit: (data: FormData) => void;
  formMethods: UseFormReturn<FormData>;
  isVirtual: boolean;
  cardTypes: string[];
  deliveryTypes: { label: string; value: string }[];
  isAllExist: boolean;
};
const mapper = (card: ICard) =>
  Number(card.card_type) === ECardType.Virtual
    ? CARD_TYPE_VIRTUAL
    : CARD_TYPE_PHYSICAL;
export const useCardCreate = ({ onSuccess }: Props): Return => {
  const setCard = useTUCardStore((state) => state.setCreateCard);
  const user = useStoreClient((state) => state.user);
  const { cards, fetchClientCards } = useStoreClient(
    selectClientCards,
    shallow
  );
  useEffect(() => {
    fetchClientCards(true);
  }, [fetchClientCards]);

  const formMethods = useForm<FormData>({
    defaultValues: {
      username: `${user.first_name} ${user.last_name}`,
      type: CARD_TYPE_PHYSICAL,
      dispatchMethod: DeliveryType.DhlExpress,
      use_company: true,
      phone: user.phone,
    },
  });
  const setValue = formMethods.setValue;
  const { cardsFilter, existedCards } = useMemo(() => {
    const cardsFilter = cards.filter((card) => card.status === "ACTIVE");
    return {
      cardsFilter,
      existedCards: cardsFilter.map(mapper),
    };
  }, [cards]);
  const isAllExist = useMemo(() => {
    return [ECardType.Virtual, ECardType.Physical].every(
      (type) =>
        cardsFilter.findIndex((card) => Number(card.card_type) === type) !== -1
    );
  }, [cardsFilter]);
  const filteredOptions = useMemo(() => {
    if (isAllExist) return [];
    if (!cardsFilter.map(mapper).includes(CARD_TYPE_VIRTUAL))
      return [CARD_TYPE_VIRTUAL];
    return [CARD_TYPE_PHYSICAL, CARD_TYPE_VIRTUAL].filter(
      //@ts-ignore
      (type) => !existedCards.includes(type)
    );
  }, [existedCards, isAllExist, cardsFilter]);
  useEffect(() => {
    setValue("type", filteredOptions[0]);
  }, [setValue, filteredOptions]);

  const createCardVirtual = useCallback(async () => {
    const { phone, nickname, password3ds } = formMethods.getValues();

    return await ExClient.createCard("create_virtual_card", {
      card_holder: `${user.first_name ?? ""} ${user.last_name ?? ""}`,
      card_name: nickname,
      card_type: 1,
      client_id: user.id,
      mobile: phone,
      pin: password3ds,
    });
  }, [formMethods, user]);

  const createCardPhysical = useCallback(async () => {
    const {
      name,
      password3ds,
      pin,
      use_company,
      nickname,
      password3ds_confirm,
      type,
      username,
      pin_confirm,
      dispatchMethod,
      ...values
    } = formMethods.getValues();
    const deliveryTo: Delivery = use_company
      ? {
          firstName: user.first_name,
          lastName: user.last_name,
          address1: user.address,
          address2: user.address,
          city: user.city,
          PostalCode: user.zip,
          phone: user.phone,
          countryCode: user.country_code,
          companyName: "",
          dispatchMethod: dispatchMethod,
        }
      : { companyName: "", dispatchMethod, ...values };
    return await ExClient.createCard("create_physical_card", {
      card_holder: use_company
        ? `${user.first_name} ${user.last_name}`
        : `${values.firstName} ${values.lastName}`,
      card_name: nickname,
      card_type: 2,
      client_id: user.id,
      mobile: user.phone,
      pin: String(pin),
      deliveryTo,
    });
  }, [formMethods, user]);

  const onSubmit = async (data: FormData) => {
    let res: ICreateCardResponse & { success?: boolean; message?: string };
    if (data.type === CARD_TYPE_VIRTUAL) {
      res = await createCardVirtual();
    } else {
      res = await createCardPhysical();
    }
    if ("success" in res && res.success === false)
      return toast.error(res?.message ?? "Something went wrong");
    setCard({ card_pan: res.cardPan, id: res.id }, res.cardType);
    onSuccess(`create_card_${data.type.toLowerCase()}`);
  };

  return {
    onSubmit,
    formMethods,
    isVirtual: formMethods.watch("type") === CARD_TYPE_VIRTUAL,
    cardTypes: filteredOptions,
    deliveryTypes: CARD_DELIVERY_TYPES,
    isAllExist,
  };
};
